import React from 'react';


export default function globeIcon() {
  return (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 370.8 207">
 
 <path id="block-1" class="group-1" d="M128,36.7c11-39.8,26.8-72.1,45.8-93.4L184.7-69l-15.8,4.8C115.5-47.9,69.5-13.6,38.6,32.9l-4.9,7.2h93.2
 L128,36.7z"/>
 <path id="block-2" class="group-2" d="M145.8,34.1l-1.8,6h83.6V-71.8l-6,1.8C190.9-61,162.6-22.1,145.8,34.1z"/>
 <path id="block-3" class="group-2" d="M326,34.1C309.2-22.1,280.8-61,250.2-70l-6-1.8V40.1h83.6L326,34.1z"/>
 <path id="block-4" class="group-2" d="M342.9,36.7l0.9,3.4h93l-4.5-7.1c-29.6-46.5-75.9-81-130.4-97.2l-15.1-4.5l10.3,12
   C316.6-33.9,332.1-2.4,342.9,36.7z"/>
 <path id="block-5" class="group-3" d="M113.2,149.9c0-27.1,3.2-56,9.9-88.4l1.2-5.6H25.5l-1.3,2.6C10.1,87,2,118,0.3,149.7l-0.2,4.9h113.1
 V149.9z"/>
 <path id="block-6" class="group-4" d="M139.1,59.4c-6.5,26-10.1,55.6-11,90.4l-0.1,4.8h99.6V55.9H140L139.1,59.4z"/>
 <path id="block-7" class="group-2" d="M343.7,149.8c-0.8-30.3-4.5-60.5-11-90.2l-0.8-3.7h-87.7v98.7h99.7L343.7,149.8z"/>
 <path id="block-8" class="group-5" d="M349.5,61.5c5.9,29.2,9.2,58.8,9.9,88.6l0.1,4.5h112.2l-0.2-4.9c-1.8-31.6-9.9-62.6-23.8-91.1l-1.3-2.6
   h-98L349.5,61.5z"/>
 <path id="block-9" class="group-4" d="M123.1,264.1c-6.3-26.6-9.9-56.3-10.7-88.4l-0.1-4.5H0l0.4,5c2.5,31.6,10.8,62.5,24.7,91l1.3,2.6h98.2
 L123.1,264.1z"/>
 <path id="block-10" class="group-6" d="M128.9,175.9c0.8,30.3,4.5,60.5,11,90.2l0.8,3.7h86.9v-98.7h-98.8L128.9,175.9z"/>
 <path id="block-11" class="group-1" d="M331.9,266.2c6.5-29.7,10.2-59.9,11-90.2l0.1-4.8h-98.8v98.7h86.9L331.9,266.2z"/>
 <path id="block-12" class="group-2" d="M359.4,175.7c-0.8,28.1-4.3,57.1-10.8,88.6l-1.2,5.6h97.9l1.3-2.5c14.7-28.3,23.1-59.4,24.7-91.3l0.2-4.9
   H359.3L359.4,175.7z"/>
 </svg>
 
  )
}